import React, { useContext } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ICacheJob } from "../../../models/Cache/ICacheJob";
import { useNavigate } from "react-router-dom";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { UserRole } from "../../../models/Users/UserRole";
import classNames from "classnames";
import { IWorkspaceDataAsset } from "src/models/Datasets/IWorkspaceDataAsset";

interface IAssetEditPane {
  alias: string;
  aliasValid: boolean;
  description: string;
  folderID: string;
  linkedCacheJob?: ICacheJob;
  setAlias: (alias: string) => void;
  setAsset: (asset: IWorkspaceDataAsset | null) => void;
  setDescription: (description: string) => void;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

const AssetEditPane = (props: IAssetEditPane) => {
  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;

  const navigate = useNavigate();

  const cachingSection = props.linkedCacheJob ? (
    <>
      <FormGroup switch className="mx-0 px-0">
        <Row>
          <Col className="flex-grow-0 me-3">
            <Label for="input-toggle-caching-enabled">Caching</Label>
          </Col>
          <Col>
            <Input
              name="input-toggle-caching-enabled"
              id="input-toggle-caching-enabled"
              data-testid="input-toggle-caching-enabled"
              type="switch"
              role="switch"
              value={props.linkedCacheJob.enabled ? "true" : "false"}
              checked={props.linkedCacheJob.enabled}
              disabled // We don't want users to be able to change this from here
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Button
          color="primary"
          outline
          onClick={() => {
            if (isAdminUser) {
              navigate(`/jobs/editCacheJob/${props.linkedCacheJob!.id}`);
            }
          }}
          className={classNames({ disabled: !isAdminUser })}
        >
          <i className="fa fa-pen-to-square fa-solid fa-sm me-1" />
          Edit Job
        </Button>
      </FormGroup>
    </>
  ) : null;

  return (
    <Card className="no-shadow">
      <Form>
        <Row className="side-pane-title mb-3">
          <Col>Configure</Col>
        </Row>
        <FormGroup>
          <Label for="assetName" style={{ fontWeight: "bold" }}>
            Alias
          </Label>
          <Input
            id="assetName"
            type="text"
            defaultValue={props.alias}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setAlias(event.target.value.trim())
            }
            invalid={!props.aliasValid}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            disabled={!isAdminUser}
          />
          <FormFeedback type="invalid">
            Data asset name must be 1 to 128 characters in length and should not
            contain square brackets.
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="description" style={{ fontWeight: "bold" }}>
            Description
          </Label>
          <Input
            id="description"
            type="textarea"
            defaultValue={props.description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setDescription(event.target.value)
            }
            disabled={!isAdminUser}
          />
        </FormGroup>
        {cachingSection}
      </Form>
    </Card>
  );
};

export default AssetEditPane;
