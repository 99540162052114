import { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Container, Card, Button, Spinner } from "reactstrap";
import { withRouter } from "../../../components/withRouter";
import { useParams, useNavigate } from "react-router-dom";
import EditFolderCard from "./EditFolderCard";
import Sidepane from "../Sidepane";
import FolderEditPane, { IFolderAssetPaneFormValues } from "./FolderEditPane";
import { IFolderAsset } from "../../../models";
import Loader from "../../../components/Loader";
import { RequestType } from "../../../components/withAPI";
import { useAPI } from "../../../components/useAPI";
import FullScreenDashboardContent from "../../../components/FullScreenDashboardContent";
import TitleWithBackButton from "../../../components/TitleWithBackButton";
import { BreadcrumbType } from "../../../models/Datasets/BreadcrumbType";
import { DatasetsBreadcrumbs } from "../../../components/breadcrumb/DatasetBreadcrumbs";
import { PermissionFlag } from "../../../utility/PermissionFlag";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { UserRole } from "../../../models/Users/UserRole";
import { useAppSelector } from "../../../redux/hooks";
import { ModalContext } from "../../../routes/ModalContext";
import { IModalProps } from "../../../components/CDataModal";
import { ToastrSuccess } from "../../../services/toastrService";
import { FormikProps } from "formik";
import { ErrorTable, IErrorTableRow } from "../../../components/ErrorTable";

const FolderDetails = () => {
  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;
  const formikRef = useRef<FormikProps<IFolderAssetPaneFormValues>>(null);
  const modalFunctions = useContext(ModalContext);
  const [folderDetails, setFolderDetails] = useState<IFolderAsset | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formState, setFormState] = useState({
    dirty: false,
    hasFolderNameError: false,
  });
  const [deleteAssetErrors, setDeleteAssetErrors] = useState<IErrorTableRow[]>(
    [],
  );
  const api = useAPI();
  const { folderId } = useParams();
  const { workspaceId } = useParams();
  const userId = useAppSelector((state) => state.user.id);
  const navigate = useNavigate();

  const getWorkspacePermissions = async () => {
    let pageAllowed = false;
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/users/${userId}`,
      "Failed to user info due to the following error:",
    );

    if (status === 200) {
      const workspacePermissions = payload.workspacePermissions;

      workspacePermissions.forEach(
        (workspaceItem: { workspaceId: string; opsAllowed: number }) => {
          if (workspaceItem.workspaceId === workspaceId) {
            pageAllowed =
              (PermissionFlag.SELECT & workspaceItem.opsAllowed) > 0 ||
              (PermissionFlag.INSERT & workspaceItem.opsAllowed) > 0 ||
              (PermissionFlag.UPDATE & workspaceItem.opsAllowed) > 0 ||
              (PermissionFlag.DELETE & workspaceItem.opsAllowed) > 0 ||
              (PermissionFlag.EXECUTE & workspaceItem.opsAllowed) > 0;
          }
        },
      );
    }

    if (!pageAllowed) {
      navigate("/datasets");
    }
  };

  useEffect(() => {
    const getFolder = async () => {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        `/workspaces/${workspaceId}/folders/${folderId}`,
        "Error fetching folder.",
      );
      if (status === 200) {
        const folder = payload as IFolderAsset;
        setFolderDetails(folder);
        setLoading(false);
      }
    };
    if (!isAdminUser) {
      getWorkspacePermissions();
    }

    getFolder();
  }, []); // eslint-disable-line

  const handleSaveClick = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  async function handleDeleteFolder() {
    setIsProcessing(true);
    const { status } = await api.callAPI(
      RequestType.Delete,
      `/workspaces/${workspaceId}/folders/${folderDetails?.id}`,
      "Failed to delete folder due to the following error:",
    );

    if (status === 200) {
      ToastrSuccess(
        "Folder successfully deleted",
        `${folderDetails?.name} was successfully deleted.`,
      );
      modalFunctions.toggleModal();
      navigate(`/datasets/workspace/${workspaceId}`);
    }
    setIsProcessing(false);
  }

  function openDeleteFolderModal() {
    const message =
      "You are about to delete this Folder. Are you sure you want to proceed?";
    const confirmButton = (
      <Button color="danger" onClick={handleDeleteFolder}>
        <div className="icon no-pointer-event" />
        Delete
      </Button>
    );
    const modal = {
      title: "Delete Folder",
      body: <div>{message}</div>,
      primaryButton: confirmButton,
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Close
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  if (loading || !folderDetails) {
    return <Loader />;
  }

  return (
    <FullScreenDashboardContent>
      <Container fluid className="mt-0 pages-datasets-FolderDetails">
        <Row className="h-100 w-100 ms-0">
          <Col className="edit-folder-card-col">
            <DatasetsBreadcrumbs
              workspaceId={workspaceId}
              folderId={folderId}
              folderName={folderDetails.name}
              page={BreadcrumbType.Folder}
            />
            <Row>
              <Col>
                <TitleWithBackButton title={folderDetails.name} />
              </Col>
              <Col className="text-end">
                {isAdminUser ? (
                  <>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={openDeleteFolderModal}
                      data-testid="button-delete"
                    >
                      <i className="fa fa-solid fa-xmark fa-sm" /> Delete
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      data-testid="button-save"
                      disabled={
                        !formState.dirty || formState.hasFolderNameError
                      }
                      className="ms-1"
                      onClick={handleSaveClick}
                    >
                      <i className="fa fa-solid fa-floppy-disk color-white fa-sm" />{" "}
                      Save Changes
                    </Button>
                  </>
                ) : null}
              </Col>
            </Row>
            {deleteAssetErrors.length > 0 && (
              <ErrorTable
                title={`${deleteAssetErrors.length} Errors`}
                entityName="Data Asset"
                data={deleteAssetErrors}
                className="mb-4"
              />
            )}
            <Card className="edit-folder-card">
              <EditFolderCard
                folderName={folderDetails.name}
                setDeleteAssetErrors={setDeleteAssetErrors}
              />
            </Card>
          </Col>
          <Col className="sidepane-col">
            <Sidepane>
              <FolderEditPane
                folderDetails={folderDetails}
                setFolderDetails={setFolderDetails}
                ref={formikRef}
                setFormState={setFormState}
              />
            </Sidepane>
          </Col>
        </Row>
      </Container>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </FullScreenDashboardContent>
  );
};

export default withRouter(FolderDetails);
