import {
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Col, Input, Row, FormFeedback, Spinner } from "reactstrap";
import { RequestType } from "../../../components/withAPI";
import { ToastrSuccess } from "../../../services/toastrService";
import { Formik, Form, FormikProps, FormikErrors } from "formik";
import * as Yup from "yup";
import { useAPI } from "../../../components/useAPI";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { UserRole } from "../../../models/Users/UserRole";
import { useNameAvailabilityCheck } from "../../../hooks/useNameAvailabilityCheck";
import { IWorkspace } from "../../../models/Datasets/IWorkspace";
import { IFullPermission } from "../../connections/components/PermissionsCard";
import { IWorkspacePermission } from "../../../models/Permissions/IWorkspacePermission";
import { IUpdateWorkspaceRequest } from "../../../models/Datasets/IUpdateWorkspaceRequest";
import { CDataTypography } from "src/components/text/CDataTypography";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";

interface IWorkspaceGeneralCard {
  workspaceDetails: IWorkspace;
  setWorkspaceDetails: Dispatch<SetStateAction<IWorkspace | null>>;
  setIsProcessing: (isProcessing: boolean) => void;
  setFormState: (params: {
    dirty: boolean;
    hasWorkspaceNameError: boolean;
  }) => void;
  permissions: IFullPermission[];
}

export type IWorkspacePaneFormValues = {
  WorkspaceName: string;
  Description: string;
};

const WorkspaceGeneralCard = forwardRef(
  (
    props: IWorkspaceGeneralCard,
    ref: ForwardedRef<FormikProps<IWorkspacePaneFormValues>>,
  ) => {
    const userAccount = useContext(AuthorizeContext);
    const isAdminUser = userAccount && userAccount.role === UserRole.Admin;
    const api = useAPI();
    const { getRestrictedNames } = useNameAvailabilityCheck();

    const [unavailableNames, setUnavailableNames] = useState<string[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const updateParentFormState = (
      dirty: boolean,
      errors: FormikErrors<IWorkspacePaneFormValues>,
    ) => {
      props.setFormState({
        dirty: dirty,
        hasWorkspaceNameError: !!errors?.WorkspaceName,
      });
    };

    useEffect(() => {
      const fetchRestrictedNames = async () => {
        const names = await getRestrictedNames({
          excludedName: props.workspaceDetails.name,
        });
        setUnavailableNames(names ?? []);
      };

      fetchRestrictedNames();
    }, []); // eslint-disable-line

    const handleSave = async (values: IWorkspacePaneFormValues) => {
      setIsProcessing(true);

      const data: IUpdateWorkspaceRequest = {
        name: values.WorkspaceName,
        description: values.Description,
        permissions: props.permissions.map((p) => {
          return {
            userId: p.id,
            workspaceId: props.workspaceDetails.id,
            opsAllowed: p.opsAllowed,
          } as IWorkspacePermission;
        }),
      };

      const url = `/workspaces/${props.workspaceDetails.id}`;
      const { status } = await api.callAPI(
        RequestType.Put,
        url,
        "Failed to update workspace due to the following error:",
        data,
      );
      if (status === 200) {
        props.setWorkspaceDetails((prevWorkspaceDetails: IWorkspace | null) => {
          const newWorkspaceDetails = { ...prevWorkspaceDetails };
          newWorkspaceDetails.name = values.WorkspaceName;
          newWorkspaceDetails.description = values.Description;
          return newWorkspaceDetails as IWorkspace;
        });

        ToastrSuccess(
          "Workspace successfully saved",
          `${values.WorkspaceName} was successfully updated.`,
        );

        props.setFormState({ dirty: false, hasWorkspaceNameError: false });
      }

      setIsProcessing(false);
    };

    function renderWorkspaceGeneralCard() {
      const initialFormValues: IWorkspacePaneFormValues = {
        WorkspaceName: props.workspaceDetails?.name || "",
        Description: props.workspaceDetails?.description || "",
      };

      const validationSchema = Yup.object().shape({
        WorkspaceName: Yup.string()
          .matches(
            /^\w[\w_.() -]{0,99}(?<! )$/,
            "Workspace name must only consist of letters, numbers, underscores, hyphens, spaces, and are limited to 100 characters.",
          )
          .required("Please enter a Workspace name")
          .test(
            "unique-name",
            "This name is already used in a Connection or Workspace.",
            function (value) {
              return !unavailableNames?.some(
                (name) => name?.toLowerCase() === value?.toLowerCase(),
              );
            },
          ),
      });

      return (
        <CDataCard>
          <CDataCardBody>
            <Formik
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleSave}
              enableReinitialize
              innerRef={ref}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="side-pane-title mb-3">
                    <Col>
                      <CDataTypography variant="typography-variant-card-title">
                        General
                      </CDataTypography>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="workspace-name-field">
                      <CDataTypography
                        variant="typography-variant-body-medium"
                        className="required mb-2"
                      >
                        Workspace Name
                      </CDataTypography>
                      <Input
                        required
                        id="workspaceName"
                        type="text"
                        name="WorkspaceName"
                        value={values.WorkspaceName}
                        invalid={Boolean(
                          touched.WorkspaceName && errors.WorkspaceName,
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        data-testid="workspace-name-field"
                        disabled={!isAdminUser}
                        onKeyUp={() => {
                          updateParentFormState(dirty, errors);
                        }}
                      />
                      {!!touched.WorkspaceName && (
                        <FormFeedback type="invalid">
                          {errors.WorkspaceName?.toString()}
                        </FormFeedback>
                      )}
                    </Col>
                    <Col className="workspace-description-field">
                      <CDataTypography
                        variant="typography-variant-body-medium"
                        className="mb-2"
                      >
                        Description
                      </CDataTypography>
                      <Input
                        id="description"
                        name="Description"
                        type="text"
                        value={values.Description}
                        onChange={handleChange}
                        data-testid="description-field"
                        disabled={!isAdminUser}
                        onKeyUp={() => {
                          updateParentFormState(dirty, errors);
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            <div hidden={!isProcessing}>
              <div className="loading-background" />
              <Spinner
                className="spinner-border loading-spinner"
                color="info"
              />
            </div>
          </CDataCardBody>
        </CDataCard>
      );
    }

    return <>{renderWorkspaceGeneralCard()}</>;
  },
);
WorkspaceGeneralCard.displayName = "WorkspaceGeneralCard";

export default WorkspaceGeneralCard;
