import { IBreadcrumbInfoList } from "src/models/Datasets/IBreadcrumbInfoList";
import { cdataFetch } from "../cdataFetch";

export async function getAssetBreadcrumbs(
  workspaceId: string,
  dataAssetId: string,
) {
  return await cdataFetch<IBreadcrumbInfoList>({
    method: "GET",
    url: `/workspaces/${workspaceId}/assets/${dataAssetId}/breadcrumbs`,
  });
}
