import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  CardText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { IWorkspaceListItem } from "../../../models";
import { useWorkspaceModals } from "../../../hooks/useWorkspaceModals";
import classNames from "classnames";
interface IWorkspaceGridTile {
  workspace: IWorkspaceListItem;
  setWorkspaces: (workspaceItem: IWorkspaceListItem[]) => void;
  selectedWorkspaces: any[];
  toggleCheckbox: (checkbox: IWorkspaceListItem) => void;
  workspaces: IWorkspaceListItem[];
  disabled: boolean;
  isAdminUser: boolean;
}

const enum DropdownMenuOptions {
  Edit,
  Delete,
  Copy,
  Move,
}

const WorkspaceGridTile = (props: IWorkspaceGridTile) => {
  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const navigate = useNavigate();

  const allWorkspaces = [...props.selectedWorkspaces, ...props.workspaces];

  // In case we leave selected a workspace by checkbox but we decide an action on another not checked,
  // This filter will remove duplicates and fix the need to refresh the age to see the updated list after the action was completed.
  const filteredAllWorkspaces = [
    ...new Map(
      allWorkspaces.map((workspace) => [workspace.id, workspace]),
    ).values(),
  ];

  const workspaceModalFunctions = useWorkspaceModals(
    filteredAllWorkspaces,
    props.setWorkspaces,
    setIsProcessing,
  );

  const handleDropdownClick = (option: DropdownMenuOptions) => {
    setIsActionMenuOpen(false);
    switch (option) {
      case DropdownMenuOptions.Edit: {
        navigate("/datasets/workspace/" + props.workspace.id);
        break;
      }
      case DropdownMenuOptions.Delete: {
        workspaceModalFunctions.deleteWorkspaceConfirmationModal(
          props.workspace,
        );
        break;
      }
      case DropdownMenuOptions.Copy: {
        workspaceModalFunctions.copyWorkspace(props.workspace.id);
        break;
      }
    }
  };

  return (
    <div
      className={classNames("datasets-grid-tile", {
        "disabled-grid": !props.isAdminUser && props.disabled,
      })}
      id="grid-tile"
    >
      <Col id={props.workspace.id} className="hover-background">
        <Card
          className="m-auto shadow-sm h-100 cursor-pointer"
          onClick={() => handleDropdownClick(DropdownMenuOptions.Edit)}
        >
          <Col className="folder-icon">
            {props.isAdminUser ? (
              <Col className="checkbox">
                <Input
                  type="checkbox"
                  checked={props.selectedWorkspaces.some(
                    (tile) => tile.id === props.workspace.id,
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    props.toggleCheckbox(props.workspace);
                  }}
                  className="m-0 cursor-pointer"
                />
              </Col>
            ) : null}
            <i className="gridIcon fa-regular fa-folder-open" />
            {props.isAdminUser ? (
              <Col className="ellipses-icon">
                <Dropdown
                  isOpen={isActionMenuOpen}
                  toggle={(event: React.MouseEvent | React.KeyboardEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsActionMenuOpen(!isActionMenuOpen);
                  }}
                >
                  <DropdownToggle nav>
                    <i className="fa fa-regular fa-ellipsis-vertical p-2"></i>
                  </DropdownToggle>
                  <DropdownMenu
                    direction="left"
                    className="options-menu inline-dropdown"
                  >
                    <DropdownItem
                      onClick={() =>
                        handleDropdownClick(DropdownMenuOptions.Edit)
                      }
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        handleDropdownClick(DropdownMenuOptions.Delete)
                      }
                    >
                      Delete
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        handleDropdownClick(DropdownMenuOptions.Copy)
                      }
                    >
                      Copy
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            ) : null}
          </Col>
          <CardBody className="text-center flex-column">
            <CardText
              id={`row-${props.workspace.id}`}
              className="driver-card-title"
              tag="div"
            >
              {props.workspace.name}
            </CardText>
            <CardText
              id={`row-${props.workspace.id}`}
              className="driver-card-title"
              tag="div"
            >
              {props.workspace.description}
            </CardText>
            <Col className="assets-count">
              <i className="fa-regular fa-files assets-icon"></i>&nbsp;
              {props.workspace.childCount}&nbsp;assets
            </Col>
          </CardBody>
        </Card>
      </Col>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </div>
  );
};

export default WorkspaceGridTile;
