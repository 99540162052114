import { Col, Row, RowProps } from "reactstrap";
import "./BigNavigationButton.scss";

interface IBigButtonProps {
  title: string;
  onClick: RowProps["onClick"];
  className?: string;
}

function BigNavigationButton(props: IBigButtonProps) {
  return (
    <Row
      className={`components-buttons-BigNavigationButton ${props.className ?? ""}`}
      onClick={props.onClick}
    >
      <Col>{props.title}</Col>
      <Col className="align-self-center">
        <i className="fa fa-regular fa-chevron-right float-end" />
      </Col>
    </Row>
  );
}

export default BigNavigationButton;
