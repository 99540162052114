import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Badge, Button, Container, Col, Row, Spinner } from "reactstrap";

import ColumnsTab from "./Tabs/ColumnsTab";
import SQLTab from "./Tabs/SQLTab";
import PreviewTab from "./Tabs/PreviewTab";
import TabWrapper, { ITabComponent } from "../../../components/TabWrapper";
import { useAPI } from "../../../components/useAPI";
import Sidepane from "../Sidepane";
import AssetEditPane from "./AssetEditPane";
import Loader from "../../../components/Loader";
import { AssetType, IColumnOverride } from "../../../models";
import { RequestType } from "../../../components/withAPI";
import FullScreenDashboardContent from "../../../components/FullScreenDashboardContent";
import TitleWithBackButton from "../../../components/TitleWithBackButton";
import { BreadcrumbType } from "../../../models/Datasets/BreadcrumbType";
import { DatasetsBreadcrumbs } from "../../../components/breadcrumb/DatasetBreadcrumbs";
import { IBreadcrumbInfoList } from "../../../models/Datasets/IBreadcrumbInfoList";
import { ToastrSuccess } from "../../../services/toastrService";
import { useAssetModals } from "../../../hooks/useAssetModals";
import { ICacheJob } from "../../../models/Cache/ICacheJob";
import { getCacheJob } from "../../jobs/components/CachingApiCalls";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { UserRole } from "../../../models/Users/UserRole";
import { IWorkspaceDataAsset } from "src/models/Datasets/IWorkspaceDataAsset";

export enum AssetDetailsTab {
  Columns = "1",
  Preview = "2",
  SQLTab = "3",
}

const AssetDetails = () => {
  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;

  const [activeTab, setActiveTab] = useState<string>(AssetDetailsTab.Columns);
  const [alias, setAlias] = useState<string>("");
  const [aliasValid, setAliasValid] = useState<boolean>(true);
  const [asset, setAsset] = useState<IWorkspaceDataAsset | null>(null);
  const [assetFolder, setAssetFolder] = useState("");
  const [breadcrumbList, setBreadcrumbList] = useState<IBreadcrumbInfoList>();
  const [colOverride, setColOverride] = useState<{
    [key: string]: boolean;
  } | null>(null);
  const [description, setDescription] = useState<string>("");
  const [linkedCacheJob, setLinkedCacheJob] = useState<ICacheJob | null>(null);
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [workspace, setWorkspace] = useState("");

  useEffect(() => {
    async function fetchDescribeDataAsset() {
      const asset = await describeDataAsset();
      if (asset) {
        setAsset(asset);
        await getFolderNameOfAsset(asset.parentId);
        updateColOverride(asset.columnOverrides);

        await getBreadcrumbs();
        await describeWorkspace();
        await getLinkedCacheJob(asset);
        setLoading(false);
      }
    }
    fetchDescribeDataAsset();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (asset) {
      setAlias(asset.alias!);
      setDescription(asset.description!);
    }
  }, [asset]);

  // Validation for asset name if its length is greather than 0
  useEffect(() => {
    setAliasValid(
      alias.length > 0 &&
        alias.length <= 128 &&
        !alias.includes("[") &&
        !alias.includes("]") &&
        alias.trim() !== "",
    );
  }, [alias]);

  // Monitors for changes in asset attributes
  useEffect(() => {
    if (asset) {
      setUnsavedChanges(
        alias !== asset.alias || description !== asset.description,
      );
    }
  }, [alias, description]); // eslint-disable-line

  // Enables the save button if there are changed attributes and the name is valid
  useEffect(() => {
    setSaveEnabled(aliasValid && unsavedChanges);
  }, [aliasValid, unsavedChanges]);

  const handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsProcessing(true);
    event.preventDefault();

    const colOverridePayload: IColumnOverride[] = [];
    for (const key in colOverride) {
      if (colOverride[key]) {
        colOverridePayload.push({ columnName: key, isKey: colOverride[key] });
      }
    }

    const data = {
      alias: alias,
      description: description,
      columnOverrides: colOverridePayload,
    };

    const url = `/workspaces/${workspaceId}/assets/${assetId}`;
    const { status } = await api.callAPI(
      RequestType.Put,
      url,
      "Failed to update asset due to the following error:",
      data,
    );
    if (status === 200) {
      ToastrSuccess(
        "Asset successfully saved",
        `${asset?.alias} was successfully updated.`,
      );
      setAsset((prevAsset) => {
        return {
          ...prevAsset!,
          alias: alias,
          description: description,
          columnOverrides: colOverridePayload,
        };
      });
      setUnsavedChanges(false);
    }
    setIsProcessing(false);
  };

  const assetModals = useAssetModals(setIsProcessing);
  const api = useAPI();
  const { workspaceId, assetId } = useParams();
  const navigate = useNavigate();

  const workspaceName = breadcrumbList?.breadcrumbs.filter(
    (value) => value.type === BreadcrumbType.Workspace,
  )[0].name;
  const folderName =
    breadcrumbList?.breadcrumbs.filter(
      (value) => value.type === BreadcrumbType.Folder,
    )[0]?.name ?? "Root";
  const onDeleteURL = asset?.parentId
    ? `/datasets/workspace/${workspaceId}/folder/${asset?.parentId}`
    : `/datasets/workspace/${workspaceId}`;

  function updateColOverride(columnOverrides: IColumnOverride[]) {
    const newColOverrideConfig: { [key: string]: boolean } = {};
    columnOverrides.forEach((ele: IColumnOverride) => {
      newColOverrideConfig[ele.columnName!] = ele.isKey!;
    });
    setColOverride({ ...colOverride, ...newColOverrideConfig });
  }

  async function describeWorkspace() {
    const url = `/workspaces/${workspaceId}`;
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      url,
      "Error fetching workspace details:",
    );
    if (status === 200) {
      const result = payload;
      setWorkspace(result.name);
    }
  }

  async function describeDataAsset() {
    const url = `/workspaces/${workspaceId}/assets/${assetId}`;
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      url,
      "Error fetching asset details:",
    );
    if (status === 200) {
      return payload;
    } else if (status === 404) {
      navigate(-1);
    }
  }

  async function getBreadcrumbs() {
    const url = `/workspaces/${workspaceId}/assets/${assetId}/breadcrumbs`;
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      url,
      "Error fetching asset details:",
    );
    if (status === 200) {
      setBreadcrumbList(payload as IBreadcrumbInfoList);
    }
  }

  async function getLinkedCacheJob(assetDetails: IWorkspaceDataAsset) {
    if (assetDetails.linkedCacheJobId) {
      const cacheJob = await getCacheJob(
        api.callAPI,
        assetDetails.linkedCacheJobId,
      );
      setLinkedCacheJob(cacheJob);
    }
  }

  const assetQuery = `SELECT * FROM [${workspaceName}].[${folderName}].[${asset?.alias}]`;

  async function getFolderNameOfAsset(parentId: string) {
    if (parentId) {
      //if parentId is not null hence folder exist for asset
      const url = `/workspaces/${workspaceId}/folders/${parentId}`;
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        url,
        "Error fetch asset details:",
      );
      if (status === 200) {
        setAssetFolder(payload.name);
      }
    } else {
      setAssetFolder("ROOT");
    }
  }

  const cachingBadge =
    linkedCacheJob != null && linkedCacheJob.enabled === true ? (
      <Col className="assets-count mx-2 p-0">
        <Badge
          color=""
          className="badge-quaternary"
          data-testid="badge-caching"
        >
          Caching
        </Badge>
      </Col>
    ) : null;

  if (loading || !asset) {
    return <Loader />;
  }

  return (
    <FullScreenDashboardContent>
      <Container fluid className="mt-0 pages-datasets-AssetDetails">
        <Row className="edit-asset-row">
          <Col className="edit-asset-card-col">
            <DatasetsBreadcrumbs
              workspaceId={workspaceId}
              dataAssetId={assetId}
              folderName={asset!.alias}
              page={BreadcrumbType.Data}
            />
            <Row>
              <Col className="flex-grow-0 pe-0">
                <TitleWithBackButton title={asset.alias} />
              </Col>
              {cachingBadge}
              {isAdminUser ? (
                <Col>
                  <Row className="delete-save-row align-center gap-2 mt-3">
                    <Col className="text-end">
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() =>
                          assetModals.openDeleteAssetModal(
                            assetId as string,
                            AssetType.Data,
                            alias,
                            () => navigate(onDeleteURL),
                          )
                        }
                        className="me-1"
                        data-testid="button-delete"
                      >
                        <i className="fa fa-solid fa-xmark fa-sm" /> Delete
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        data-testid="button-save"
                        disabled={!saveEnabled}
                        onClick={(event) => handleSave(event)}
                        className="float-end"
                      >
                        <i className="fa fa-solid fa-floppy-disk color-white fa-sm" />{" "}
                        Save Changes
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ) : null}
            </Row>
            <TabWrapper
              tabs={
                [
                  {
                    tabName: "Columns",
                    tabEnum: "1",
                    tabComponent: (
                      <>
                        <ColumnsTab
                          sourceCatalog={asset.sourceCatalog!}
                          sourceSchema={asset.sourceSchema!}
                          sourceTable={asset.sourceTable!}
                          sourceAlias={asset.alias!}
                          assetQuery={assetQuery}
                          driverName={asset.driver!}
                          workspaceName={workspaceName!}
                          folderName={assetFolder}
                          assetName={asset.alias!}
                          colOverRide={colOverride!}
                          setColOverRide={setColOverride}
                          setUnsavedChanges={setUnsavedChanges}
                          isAdminUser={isAdminUser}
                        />
                      </>
                    ),
                    isVisible: true,
                  },
                  {
                    tabName: "Preview",
                    tabEnum: "2",
                    tabComponent: (
                      <>
                        <PreviewTab
                          sourceCatalog={workspace}
                          sourceSchema={assetFolder}
                          sourceTable={asset.alias}
                          activeTab={activeTab}
                          assetQuery={assetQuery}
                        />
                      </>
                    ),
                    isVisible: true,
                  },
                  {
                    tabName: "SQL",
                    tabEnum: "3",
                    tabComponent: (
                      <>
                        <SQLTab
                          sourceCatalog={workspace}
                          sourceSchema={assetFolder}
                          sourceTable={asset.alias!}
                          assetQuery={assetQuery}
                        />
                      </>
                    ),
                    isVisible: true,
                  },
                ] as ITabComponent[]
              }
              onTabChange={setActiveTab}
            />
          </Col>
          <Col className="sidepane-col">
            <Sidepane>
              <AssetEditPane
                alias={asset.alias!}
                aliasValid={aliasValid}
                description={asset.description!}
                folderID={asset.parentId!}
                linkedCacheJob={linkedCacheJob!}
                setAlias={setAlias}
                setAsset={setAsset}
                setDescription={setDescription}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Sidepane>
          </Col>
        </Row>
      </Container>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </FullScreenDashboardContent>
  );
};

export default AssetDetails;
