import { useContext } from "react";
import { ModalContext } from "../routes/ModalContext";
import { ToastrError, ToastrSuccess } from "../services/toastrService";
import { Button } from "reactstrap";
import { useAPI } from "../components/useAPI";
import { RequestType } from "../components/withAPI";
import { IModalProps } from "../components/CDataModal";
import { AssetType, IBatchDeleteResponse } from "../models";
import { useParams } from "react-router-dom";
import { IAssetListItem } from "../models/Datasets/IAssetListItem";
import { IFailedDeletedIdDetail } from "../models/Batch/IFailedDeletedIdDetail";
import { IErrorTableRow } from "../components/ErrorTable";

export function useAssetModals(
  setIsProcessing: (isProcessing: boolean) => void,
) {
  const modalFunctions = useContext(ModalContext);
  const api = useAPI();
  const { workspaceId } = useParams();

  function openDeleteAssetModal(
    id: string,
    type: AssetType,
    alias: string,
    onSuccess?: (id: string) => void,
  ) {
    const modal = {
      title: "Delete Asset",
      body: (
        <text>
          You are about to delete the asset, {alias}. Are you sure you want to
          proceed?
        </text>
      ),
      primaryButton: (
        <Button
          color="danger"
          onClick={() => deleteAssetOrFolder(id, type, alias, onSuccess)}
        >
          Delete
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      modalSize: "md",
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  async function deleteAssetOrFolder(
    id: string,
    type: AssetType,
    alias: string,
    onSuccess?: (id: string) => void,
  ) {
    setIsProcessing(true);
    const url =
      type === AssetType.Folder
        ? `/workspaces/${workspaceId}/folders/${id}`
        : `/workspaces/${workspaceId}/assets/${id}`;

    const { status } = await api.callAPI(
      RequestType.Delete,
      url,
      "Error deleting asset:",
    );
    if (status === 200) {
      ToastrSuccess(
        "Asset successfully deleted",
        `${alias} was successfully deleted.`,
      );
      if (onSuccess) {
        onSuccess(id);
      }
    }

    setIsProcessing(false);
    modalFunctions.toggleModal();
  }

  function openBatchDeleteAssetsModal(
    assets: IAssetListItem[],
    onSuccess?: (
      deletedAssetIds: string[],
      updatedBulkDeleteFailedMessages: IErrorTableRow[],
    ) => void,
  ) {
    const bodyMessage =
      assets.length === 1
        ? `You are about to delete the asset, ${assets[0].alias}. Are you sure you want to proceed?`
        : "You are about to delete multiple assets. Are you sure you want to proceed?";

    const modal = {
      title: "Delete Asset",
      body: <text>{bodyMessage}</text>,
      primaryButton: (
        <Button
          color="danger"
          onClick={() => batchDeleteAssetsOrFolders(assets, onSuccess)}
        >
          Delete
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      modalSize: "md",
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  async function batchDeleteAssetsOrFolders(
    assets: IAssetListItem[],
    onSuccess?: (
      deletedAssetIds: string[],
      updatedBulkDeleteFailedMessages: IErrorTableRow[],
    ) => void,
  ) {
    setIsProcessing(true);
    const updatedBulkDeleteFailedMessages: IErrorTableRow[] = [];
    const assetsToDelete = {
      assets: assets.map((asset: Partial<IAssetListItem>) => {
        return {
          assetType: asset.assetType,
          id: asset.id,
        };
      }),
    };

    const { status, payload } = await api.callAPI(
      RequestType.Delete,
      `/workspaces/${workspaceId}/assets`,
      "Error deleting assets:",
      assetsToDelete,
    );
    if (status === 200) {
      const data: IBatchDeleteResponse = payload;
      if (data.failedDeletedIdsDetail?.length > 1) {
        data.failedDeletedIdsDetail?.forEach((obj: IFailedDeletedIdDetail) => {
          updatedBulkDeleteFailedMessages.push({
            name: assets.find((a) => a.id === obj.id)?.alias,
            errorMessage: obj.error?.message,
          });
        });
      } else if (data.failedDeletedIdsDetail?.length === 1) {
        ToastrError(
          "Delete Failed",
          data.failedDeletedIdsDetail[0].error.message,
        );
      } else if (data.error) {
        ToastrError("Error", data.error.message);
      }

      if (data.deletedIds && data.deletedIds.length > 0) {
        ToastrSuccess(
          "Assets successfully deleted",
          `${payload.deletedIds.length} assets were successfully deleted.`,
        );
      }

      const deletedAssets = data.deletedIds ?? [];

      if (onSuccess) {
        onSuccess(deletedAssets, updatedBulkDeleteFailedMessages);
      }
    }

    setIsProcessing(false);
    modalFunctions.toggleModal();
  }

  const modalAndAPIs = {
    openDeleteAssetModal: openDeleteAssetModal,
    openBatchDeleteAssetsModal: openBatchDeleteAssetsModal,
  };

  return modalAndAPIs;
}
