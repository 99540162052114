import { IFolderAsset, UserRole } from "../../../models";
import {
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useContext,
  useState,
} from "react";
import {
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { RequestType } from "../../../components/withAPI";
import { ToastrSuccess } from "../../../services/toastrService";
import { Formik, Form, FormikErrors, FormikProps } from "formik";
import * as Yup from "yup";
import { useAPI } from "../../../components/useAPI";
import { useParams } from "react-router-dom";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";

interface IFolderEditPaneProps {
  folderDetails: IFolderAsset;
  setFolderDetails: Dispatch<SetStateAction<IFolderAsset | null>>;
  setFormState: (params: {
    dirty: boolean;
    hasFolderNameError: boolean;
  }) => void;
}

export type IFolderAssetPaneFormValues = {
  FolderName: string;
  Description: string;
};

const FolderEditPane = forwardRef(
  (
    props: IFolderEditPaneProps,
    ref: ForwardedRef<FormikProps<IFolderAssetPaneFormValues>>,
  ) => {
    const userAccount = useContext(AuthorizeContext);
    const isAdminUser = userAccount && userAccount.role === UserRole.Admin;

    const api = useAPI();
    const { workspaceId } = useParams();

    const [isProcessing, setIsProcessing] = useState(false);

    const updateParentFormState = (
      dirty: boolean,
      errors: FormikErrors<IFolderAssetPaneFormValues>,
    ) => {
      props.setFormState({
        dirty: dirty,
        hasFolderNameError: !!errors?.FolderName,
      });
    };

    const handleSave = async (values: IFolderAssetPaneFormValues) => {
      setIsProcessing(true);
      const data = {
        name: values.FolderName,
        description: values.Description,
      };

      const url = `/workspaces/${workspaceId}/folders/${props.folderDetails.id}`;
      const { status } = await api.callAPI(
        RequestType.Put,
        url,
        "Failed to update folder due to the following error:",
        data,
      );
      if (status === 200 || status === 204) {
        props.setFolderDetails((prevFolderDetails: IFolderAsset | null) => {
          const newFolderDetails = {
            ...prevFolderDetails,
            name: values.FolderName,
            description: values.Description,
          };
          return newFolderDetails as IFolderAsset;
        });
        ToastrSuccess(
          "Folder successfully saved",
          `${values.FolderName} was successfully updated.`,
        );
      }
      setIsProcessing(false);
    };

    const initialFormValues: IFolderAssetPaneFormValues = {
      FolderName: props.folderDetails?.name || "",
      Description: props.folderDetails?.description || "",
    };

    const validationSchema = Yup.object().shape({
      FolderName: Yup.string()
        .matches(
          /^\w[\w_ -]{0,99}(?<! )$/,
          "Folder name must only consist of letters, numbers, underscores, hyphens, and are limited to 100 characters.",
        )
        .required("Please enter a Folder name"),
    });

    function renderFolderEditPane() {
      return (
        <Card>
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            innerRef={ref}
            enableReinitialize
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="side-pane-title mb-3">
                  <Col>Configure</Col>
                </Row>
                <FormGroup>
                  <Label for="folderName" className="required">
                    Folder Name
                  </Label>
                  <Input
                    required
                    id="folderName"
                    type="text"
                    name="FolderName"
                    value={values.FolderName}
                    invalid={Boolean(touched.FolderName && errors.FolderName)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    data-testid="folder-name-field"
                    disabled={!isAdminUser}
                    onKeyUp={() => {
                      updateParentFormState(dirty, errors);
                    }}
                  />
                  {!!touched.FolderName && (
                    <FormFeedback type="invalid">
                      {errors.FolderName?.toString()}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    id="description"
                    name="Description"
                    type="textarea"
                    value={values.Description}
                    onChange={handleChange}
                    data-testid="description-field"
                    disabled={!isAdminUser}
                    onKeyUp={() => {
                      updateParentFormState(dirty, errors);
                    }}
                  />
                </FormGroup>
              </Form>
            )}
          </Formik>
          <div hidden={!isProcessing}>
            <div className="loading-background" />
            <Spinner className="spinner-border loading-spinner" color="info" />
          </div>
        </Card>
      );
    }

    return <>{renderFolderEditPane()}</>;
  },
);

FolderEditPane.displayName = "FolderEditPane";
export default FolderEditPane;
